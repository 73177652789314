import React from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "../../Home/ScrollIndicator";

const HistoriquePage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        DISCIPLINE
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-center text-gray-800 dark:text-white mb-4 font-montserrat uppercase">
        Historique
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="mt-8 mx-auto p-4 max-w-[1280px] space-y-8">
        {/* Section Introduction */}
        <div className="bg-blue-50 rounded-lg shadow-lg p-6 flex flex-col md:flex-row items-center transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <div className="md:w-2/3 p-4 text-gray-700">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">
              LE KARATE DO
            </h3>
            <p className="mb-4 text-gray-700">
              Le karaté ou art de la main vide ("KARA" signifie vide en japonais
              et "TE" : main) est né sur l'île d'Okinawa située à environ 450 km
              au sud-est du Japon et à 550 km de la Chine. Située entre la
              pointe du Japon et la côte chinoise, elle est à la croisée de
              plusieurs influences surtout de la Chine et du Japon mais aussi
              d'autres pays asiatiques. Ses habitants se sont souvent retrouvés
              face à l'invasion de forces étrangères. Quand au XVIIème siècle
              les japonais ont envahi l'île, ils ont trouvé des paysans qui
              maîtrisaient parfaitement le maniement des armes et les techniques
              de combat. Sous la domination des empereurs Ming, ceux-ci
              interdisent tout port d'armes de crainte que les autochtones ne se
              rebellent contre eux. Cette interdiction eut pour effet de jeter
              la population dans une opposition ouverte et de les inciter à
              travailler avec acharnement les anciennes techniques locales de
              combat sans armes. On s'entraîna intensément dans des endroits
              secrets, souvent la nuit.
            </p>
            <p className="text-gray-700">
              On peut distinguer 3 types majeurs d'arts martiaux originaires
              d'Okinawa. Leurs noms proviennent des villes dans lesquelles ils
              étaient pratiqués.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ Le <b>Shuri-te</b> (main de Shuri) était pratiqué à Shuri et
              dans ses environs et était enseigné par le maître Ito-su. C'était
              un style qui préférait la rapidité ; en effet, les mouvements et
              les esquives de ce style se voulaient rapides et agiles. C'est le
              père du Shôrin-ryu.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ Le <b>Naha-te</b> (main de Naha) était pratiqué dans le port de
              Naha et était enseigné par le Maître Higashionna. Celui-ci puisait
              son inspiration du style de combat de l'école du Shaolin
              méridional aux applications tantôt dures, tantôt douces, aux
              positions hautes, aux déplacements semi-circulaires et aux coups
              circulaires puissants. C'est le père du Shôrey-ryu.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ Le <b>Tomari-te</b> (main de Tomari) était pratiqué à Tomari et
              ne diffère guère du Shuri-te et était enseigné par le Maître
              Matsumura. Il a influencé aussi bien le shôrin-ryu que le
              shôrey-ryu. Il se caractérise par un travail de clés et de
              projections.
            </p>
            <br />
            <p className="text-gray-700">
              Ces différents styles donneront vie aux styles de karaté que nous
              connaissons aujourd'hui. Actuellement, on recense 24 écoles, les
              plus connues sont au nombre de 4. Elles se distinguent par des
              techniques, des positions et des katas caractéristiques mais aussi
              par une approche philosophique différente
            </p>
          </div>
          <div className="md:w-1/3 p-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Karatedo.svg/langfr-200px-Karatedo.svg.png"
              alt="kanji karate-do"
              className="rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
            />
          </div>
        </div>

        <div className="bg-blue-50 rounded-lg shadow-lg p-6 flex flex-col md:flex-row items-center transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <div className="md:w-2/3 p-4 text-gray-700">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">
              LES DIFFERENTES ÉCOLES
            </h3>
            <p className="mb-4 text-gray-700">
              ✅ <b>Shotokan</b> : Crée et élaboré par Maître Funakoshi Gichin. Les mouvements sont linéaires, les postures assez basses. A la vitesse d'exécution, aux attaques longues et puissantes et à l'agilité des mouvements Funakoshi Yoshitaka, successeur et fils de Funakoshi ajoutera des positions de plus en plus basses. Funakoshi Gichin fut le premier à introduire le karaté-do dans les universités japonaises en 1924. Il y a un certain nombre de style de karaté, comme le wado-ryu qui sont issus du shotokan. Ce qui pourrait démarquer le style shotokan des autres, ce sont les positions basses, la rigidité des techniques et le traditionalisme de l'entraînement.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ <b>Wado-ryu</b> :"l'école de la voix de la paix"  a été fondée par Otsuka Hironori, élève de Funakoshi Gichin. Il combina à des mouvements de Ju-Jutsu (technique de la souplesse) ceux des blocages d'Okinawa. Crée à partir du style shotokan et du ju-jutsu, il s'inspire du shuri-te et met en avant l'esquive et la souplesse. Le travail des hanches tirées et non poussées  est typique. Otsuka trouvait que le karaté de Funakoshi était austère. D'autre part, l'entraînement dans l'école shotokan ne comprenait à l'époque que des katas, ce qu'il jugeait insuffisant. Il mettra donc au point en s'inspirant d'exercices du ju-jutsu,  des systèmes de défenses et d'attaques qui se font à deux partenaires.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ <b>Shito-ryu</b> : ce style est issu à la fois du Shuri-te et du Naha-te. Fondé par Maître Mabuni, il est marqué par la subtilité et la vitesse. Les techniques s'appuient sur la mobilité du bassin, les déplacements du corps et la déviation des attaques. Mabuni nomma son école d'après les idéogrammes des deux maîtres dont il reçut l'enseignement : Itosu pour le Shuri-te et Higaonna pour le Naha-te. De ces deux styles, il prit la rapidité du shuri-te et la puissance du Naha-te. Les trois aspects qui définissent le karaté, le  "SHU" (esprit), le "GI" (technique) et le "TAÏ" (corps) doivent être constamment travaillés.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ <b>Goju-ryu</b> : Fondé en 1920 par Maître Miyagi Shôjun, de retour d'un voyage en Chine, il tire son origine du Naha-te. Il se caractérise par des mouvements réalistes en contraction et en force, par des techniques courtes effectuées à partir de positions hautes. Les blocages, souvent réalisés mains ouvertes sont circulaires et sans choc. Ce style insiste sur l'importance de l'énergie intérieure perceptible par une respiration adaptée. "Go" signifie dur et "JU" doux, aussi le goju-ryu est-il la transcription d'une voie à la fois douce et dure. C'est un mélange d'Okinawa-te et de kenpo. D'autres influences chinoises sont aussi évidentes. Une tension dynamique et une respiration adaptée constituent ses deux bases de référence.
            </p>
          </div>
        </div>

        <div className="bg-blue-50 rounded-lg shadow-lg p-6 flex flex-col md:flex-row items-center transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <div className="md:w-2/3 p-4 text-gray-700">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">
              LE KARATE
            </h3>
            <p className="mb-4 text-gray-700">
               C'est l'art martial par excellence puisqu'il apprend à se battre avec les seules armes naturelles que l'homme possède : poings, mains, genoux et pieds.

Pratiqué individuellement ou par équipes, en loisirs ou en compétition, le karaté se décline sous de nombreuses formes.

C'est une culture physique complète faisant travailler harmonieusement tous les muscles, développant la souplesse comme la résistance. Le karaté permet de garder une bonne santé et d'optimiser sa forme physique. C'est l'ambition première de nombreux pratiquants.

Le karaté est un sport complet que l'on peut pratiquer à différents âges et où l'on apprend la maîtrise de soi, on canalise son agressivité au travers des gestes que l'on apprend durant les entraînements, c'est un excellent moyen pour :

- le travail psychomoteur des membres (les techniques pieds-poings perfectionnent l'indépendance entre les membres supérieurs et inférieurs avec recherche de l'équilibre sur un pied.

- pour apprendre la latéralité des mouvements (l'entraînement nécessite précision, coordination, indépendance gestuelle entre la gauche et la droite)

- pour acquérir de nouveaux réflexes et une coordination plus fine, autant d'éléments d'équilibre et de confiance en soi.

L'enseignement comporte trois domaines d'étude complémentaires : le kihon, les katas et le kumité.
            </p>

            <br />
            <p className="text-gray-700">
              ✅ Le <b>kihon</b> consiste à répéter individuellement et la plupart du temps en groupe des techniques, des positions et des déplacements. Pratiqué avec un adversaire de façon codifié, on parle alors de kihon-kumite.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ Le <b>kata</b> est un enchaînement codifié et structuré de techniques, représentant un combat réel contre plusieurs assaillant virtuels ayant pour but l'acquisition d'automatismes ainsi que la transmission de techniques secrètes. Le kata permet au pratiquant, par de très nombreuses répétitions de tendre vers la perfection du geste.
            </p>
            <br />
            <p className="text-gray-700">
              ✅ Le <b>kumité</b> ou combat peut prendre de multiples formes, de la plus codifiée à la plus libre. Le combat peut-être pré-défini (kihon ippon kumite), fixé à un nombre précis d'attaques (ippon kumite pour une attaque,  sambon kumite pour trois attaques, gohon kumite pour cinq attaques), dit souple (ju kumite) ou libre
            </p>
          </div>
          <div className="md:w-1/3 p-4">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/3/36/K1PL_Berlin_2018-09-16_Female_Kata_108.jpg"
              alt="symbole wado-ryu"
              className="rounded-lg shadow-md transition-transform duration-300 hover:scale-105"
            />
          </div>
        </div>



      </div>
    </div>
  );
};

export default HistoriquePage;
