import React, { useState } from "react";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import ScrollIndicator from "../../Home/ScrollIndicator";

const HistoriquePage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

    const [selectedImage, setSelectedImage] = useState(null);

  // Fonction pour ouvrir l'image en plein écran
  const openImage = (imagePath) => {
    setSelectedImage(imagePath);
  };

  // Fonction pour fermer l'image plein écran
  const closeImage = () => {
    setSelectedImage(null);
  };

  // Tableaux des images et titres des sections Kata
  const kataSections = [
    {
      title: "KATA PINAN SHODAN",
      text: "Description du Kata Pinan Shodan...",
      images: [
        "/img/kata/pinan-shodan001.jpg",
        "/img/kata/pinan-shodan002.jpg",
        "/img/kata/pinan-shodan003-1.jpg",
        "/img/kata/pinan-shodan005.jpg",
      ],
    },
    {
      title: "KATA PINAN NIDAN",
      text: "Description du Kata Pinan Nidan...",
      images: [
        "/img/kata/pinan-nidan001-1.jpg",
        "/img/kata/pinan-nidan002-2.jpg",
        "/img/kata/pinan-nidan003-2.jpg",
      ],
    },
    {
      title: "KATA PINAN SANDAN",
      text: "Description du Kata Pinan Sandan...",
      images: [
        "/img/kata/pinan-sandan001.jpg",
        "/img/kata/pinan-sandan002.jpg",
        "/img/kata/pinan-sandan003.jpg",
      ],
    },
    {
      title: "KATA PINAN YODAN",
      text: "Description du Kata Pinan Yodan...",
      images: [
        "/img/kata/pinan-yodan001.jpg",
        "/img/kata/pinan-yodan002-1.jpg",
        "/img/kata/pinan-yodan003-1.jpg",
        "/img/kata/pinan-yodan004.jpg",
      ],
    },
    {
      title: "KATA PINAN GODAN",
      text: "Description du Kata Pinan Godan...",
      images: [
        "/img/kata/godan-page-2.jpg",
        "/img/kata/godan-p2.jpg",
      ],
    },
  ];

    // Liste des katas supérieurs
  const katasSuperieurs = [
    "1 - KUSHANKU",
    "2 - NAIFANCHI",
    "3 - SEISHAN",
    "4 - CHINTO",
    "5 - BASSAI",
    "6 - NISEISHI",
    "7 - ROHAI",
    "8 - WANSHU",
    "9 - JION",
    "10 - JITTE",
    "11 - SUPARIMPEI",
    "12 - UNSU",
    "13 - KUNPU",
  ];

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] dark:text-white">
      <ScrollIndicator />
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        DISCIPLINE
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-center text-gray-800 dark:text-white mb-4 font-montserrat uppercase">
        KATA WADO-RYU
      </h2>
      <DashboardNavigation role={userRole} />

      <div className="mt-8 mx-auto p-4 max-w-[1280px] space-y-8">
        {/* Section Introduction */}
        <div className="bg-blue-50 rounded-lg shadow-lg p-6 flex flex-col md:flex-row items-center transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <div className="md:w-2/3 p-4 text-gray-700">
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">
              KATA PINAN
            </h3>
            <p className="mb-4 text-gray-700">
              Les katas Pinan sont considérés comme les katas de base du karaté.
              Leur origine est récente. Ils ont été crée par le Maître Anko
              Itosu au début du XXème siècle alors que celui-ci venait
              d'introduire le karaté dans les écoles d'Okinawa comme complément
              à l'éducation physique. Destinés à cette nouvelle forme
              d'apprentissage de masse, souvent auprès de populations jeunes,
              les pinan ont été élaborés à partir de kata plus anciens et plus
              complexes (kushanku, bassaï, jion......)en ôtant les techniques
              jugées trop difficiles ou dangereuses. L'école Shotokan les
              pratique sous l'appelation de "Heian", nom donné par Gichin
              Funakoshi selon les prononciations japonaises des idéogrammes de
              Pinan. Dans les deux cas -Pinan ou Heian- signifient le calme (
              "pi" ou "hei") et la paix ("an"). Dans cet état d'esprit, tous les
              katas Pinan commencent par une technique de défense Au nombre de
              cinq, ils permettent d'aborder un grand nombre de techniques et de
              positions fondamentales.
            </p>
          </div>
        </div>

        {/* Sections Kata */}
        {kataSections.map((kata, index) => (
          <div
            key={index}
            className="bg-blue-50 rounded-lg shadow-lg p-6 flex flex-col transform transition duration-300 hover:scale-105 hover:shadow-2xl"
          >
            <h3 className="text-2xl font-semibold mb-4 text-gray-800">
              {kata.title}
            </h3>
            <p className="mb-4 text-gray-700">{kata.text}</p>
            <div className="flex flex-wrap gap-4 justify-center">
              {kata.images.map((imgSrc, imgIndex) => (
                <img
                  key={imgIndex}
                  src={imgSrc}
                  alt={`${kata.title} - Image ${imgIndex + 1}`}
                  className="w-48 h-48 object-cover cursor-pointer rounded-lg hover:scale-105 hover:shadow-2xl"
                  onClick={() => openImage(imgSrc)}
                />
              ))}
            </div>
          </div>
        ))}

        {/* Section Katas Supérieurs */}
        <div className="bg-blue-50 rounded-lg shadow-lg p-6 transform transition duration-300 hover:scale-105 hover:shadow-2xl">
          <h3 className="text-2xl font-semibold mb-4 text-gray-800">
            KATAS SUPÉRIEURS
          </h3>
          <ul className="list-disc list-inside text-gray-700">
            {katasSuperieurs.map((kata, index) => (
              <li key={index}>{kata}</li>
            ))}
          </ul>
        </div>

        {/* Image en plein écran */}
        {selectedImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50"
            onClick={closeImage}
          >
            <div className="relative">
              <img
                src={selectedImage}
                alt="Kata"
                className="max-w-full max-h-screen"
              />
              <button
                className="absolute top-2 right-2 text-white text-2xl font-bold"
                onClick={closeImage}
              >
                X
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoriquePage;
